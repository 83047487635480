<template>
  <div id="director">
    <app-navbar></app-navbar>
    <div class="whiteBalls">
      <img class="img-fluid" src="@/assets/whiteBalls.png" alt="whiteBalls">
    </div>
    <div class="redBrickBallon">
      <img class="img-fluid" src="@/assets/redBrickBallon.png" alt="redBrickBallon">
    </div>
    <div>
      <a v-on:click="$refs.galleryModal.show(getImageUrl(director.id))" class="d-block mb-4 h-100">
        <img id="ImgNew" class="img-fluid gallery-img cropped-img position-img-director gallery-picture"
             :src="getImageUrl(director.id)"
             alt="director">
      </a>
    </div>
    <div class="directorBlock pb-lg-4">
      <div class="container">

        <div class="row">
          <div id="myModal" class="modal">
            <span class="close">✕</span>
            <img class="modal-content img-fluid" id="img01">
          </div>
          <div class="col-lg-5">
          </div>
          <div class="col-lg-7">
            <h2>{{ director.name | translation }}</h2>
            <div class="pb-lg-4">
              <div v-html="html" class="textInterpret pb-lg-4 text-justify"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-1 offset-lg-11 redBallone">
      <img class="img-fluid" src="@/assets/redBallone.png" alt="redBallone">
    </div>
    <gallery-modal ref="galleryModal"/>
    <app-footer></app-footer>
  </div>
</template>

<script>
import navbar from "@/components/base/navbar";
import axios from "@/api/axiom-instance";
import config from "@/config";
import footer from "@/components/base/footer";
import GalleryModal from "@/components/base/GalleryModal";

export default {
  name: "Director",
  components: {
    'app-navbar': navbar,
    'app-footer': footer,
    'gallery-modal': GalleryModal
  },
  computed: {
    html: function () {
      return this.$options.filters.translation(this.director.description);
    }
  },
  data() {
    return {
      director: {},
    }
  },
  created() {
    if (this.$route.params.id != null) {
      this.load(this.$route.params.id)
    }
  },
  methods: {
    load: function (id) {
      axios
          .get("/director", {params: {id: id}})
          .then((success) => {
            this.director = success.data;
          })
    },
    getImageUrl: function (id) {
      return config.API_URL + "/image/preview?type=directors&name=" + id
    }
  }
}
</script>

<style scoped>
.notVisible {
  display: none;
}

h1 {
  font-size: 3.5em;
  color: #152326;
  font-family: "Transcript Pro";
}

h2 {
  font-size: 2.5em;
  font-family: "Transcript Pro";
}

h3 {
  font-family: "Transcript Pro";
  color: white;
}

a {
  color: #152326;
  text-decoration: none;
  font-size: 1em;
  font-family: 'Transcript Mono';
}

p {
  font-size: .8em;
  font-family: "Transcript Pro";
  color: #152326;
}

hr {
  padding-top: 1em;
  border-top: solid 1px #152326;
}

.textInterpret {
  color: #152326;
  font-family: "Transcript Mono";
  font-size: 1.2em;
  padding-top: 1em;
}

.directorBlock {
  margin-top: 63vh;
  margin-right: 13%;
  padding-top: 1em;
  background-color: #ed764e;
}

.redBallone {
  width: 5%;
  padding-bottom: 10em;
}

#director {
  background-color: #f3ba53;
  min-height: 50vh;
}

.whiteBalls {
  position: absolute;
  width: 6%;
  top: 79vh;
  left: 7%;
}

.redBrickBallon {
  position: absolute;
  text-align: end;
  width: 14%;
  right: 25%;
  top: 20%;
}

.position-img-director {
  position: absolute;
  top: 30vh;
  left: 14%;
}

.cropped-img {
  height: 100%;
  width: auto;
  max-height: 330px;
  max-width: 300px;
  object-fit: cover;
}

.btn {
  padding: 5px;
  border-radius: 0px;
  border: solid 1px #152326;
  color: #152326;
}

.btn:hover {
  background-color: #152326;
  color: #eae7d6;
}

/* The Modal (background) */
.img-thumbnail {
  border: none;
  background-color: #ed764e;
}

/* Caption of Modal Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation */
.modal-content, #caption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0)
  }
  to {
    -webkit-transform: scale(1)
  }
}

@keyframes zoom {
  from {
    transform: scale(0)
  }
  to {
    transform: scale(1)
  }
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  #gallery {
    padding-top: 1em;
  }
}

@keyframes textPhoto {
  100% {
    left: 7%;
    color: white;
    top: 7%;
    position: absolute;
    z-index: 3;
  }

}

</style>
